import React from "react"

import { Link } from "gatsby"


const PrimaryCta = ({ text, link, disabled }) => (
  <Link to={link} id="primary-cta" className={`button button--animated ${disabled && "disabled"}`}><span>{text}</span></Link>
)


export default PrimaryCta
